import { getAuth } from 'firebase/auth'
import { envConfig } from './envConfig'
import { AnyPlaybookType, PlaylistType, QuickGuiddeType } from 'app/types'
import { generatePath } from 'react-router-dom'
import { paths } from 'app/paths'

const PRODUCTION_PROJECT = 'guidde-production'
export const host = `https://${envConfig.firebaseConfig.authDomain}`

export const isDev = envConfig.firebaseConfig.projectId !== PRODUCTION_PROJECT
export const isLocalhost = process.env.NODE_ENV === 'development'

export const isQG = (
    playbook: AnyPlaybookType
): playbook is QuickGuiddeType => {
    return playbook.mode === 'quickGuidde'
}

export const isPlaylist = (
    playbook: AnyPlaybookType
): playbook is PlaylistType => {
    return playbook.mode === 'playlist'
}

export const request = async (
    url: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    body?: any
) => {
    const token = await getAuth().currentUser?.getIdToken()

    if (!token) return

    return fetch(`${host}${url}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            'x-guidde-client': 'embed',
            authorization: `Bearer ${token}`
        },
        ...(body && { body: JSON.stringify(body) })
    }).then(async res => {
        if (!res.ok) {
            try {
                return res.json().then(err => {
                    return Promise.reject(err)
                })
            } catch (e) {
                return Promise.reject('Something went wrong')
            }
        }
        return Promise.resolve(res.json())
    })
}

export const option = (value: any, label: any) => ({ value, label })

type OpenType = '_blank' | '_self'
export const openLink = (link: string, type: OpenType = '_blank') => {
    window.open(link, type)
}

export const generateUrlWithDomain = (path: string) => {
    return `https://${envConfig.firebaseConfig.authDomain}${[path]}`
}

export const getOS = () => {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = null

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (/Linux/.test(platform)) {
        os = 'Linux'
    }

    return { os }
}

export const detectBrowserWithVersion = () => {
    const nAgt = navigator.userAgent
    let browserName = navigator.appName
    let fullVersion = '' + parseFloat(navigator.appVersion)
    let nameOffset, verOffset, ix

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf('OPR/')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 4)
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 6)
        if ((verOffset = nAgt.indexOf('Version')) != -1)
            fullVersion = nAgt.substring(verOffset + 8)
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browserName = 'Microsoft Internet Explorer'
        fullVersion = nAgt.substring(verOffset + 5)
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browserName = 'Google Chrome'
        fullVersion = nAgt.substring(verOffset + 7)
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browserName = 'Safari'
        fullVersion = nAgt.substring(verOffset + 7)
        if ((verOffset = nAgt.indexOf('Version')) != -1)
            fullVersion = nAgt.substring(verOffset + 8)
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browserName = 'Mozilla Firefox'
        fullVersion = nAgt.substring(verOffset + 8)
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
        (nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))
    ) {
        browserName = nAgt.substring(nameOffset, verOffset)
        fullVersion = nAgt.substring(verOffset + 1)
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) != -1)
        fullVersion = fullVersion.substring(0, ix)
    if ((ix = fullVersion.indexOf(' ')) != -1)
        fullVersion = fullVersion.substring(0, ix)

    const majorVersion = parseInt('' + fullVersion, 10)
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion)
    }

    return {
        browser: browserName,
        browserVersion: fullVersion
    }
}

export const generatePlaybookURL = ({
    isPlaylist,
    isPublic,
    id: playbookId
}: AnyPlaybookType) => {
    const playbookPath = generatePath(
        !isPublic ? paths.playbookDetails : paths.sharePlaybook,
        { playbookId }
    )

    const playlistPath = generatePath(
        !isPublic ? paths.playlistDetails : paths.sharePlaylist,
        { playlistId: playbookId }
    )

    const copyLinkPath = isPlaylist ? playlistPath : playbookPath

    return generateUrlWithDomain(copyLinkPath)
}
